:root {
  --dgt-primary-color: #0A8855;
  --amplify-primary-color: var(--dgt-primary-color);
  --amplify-primary-shade: #0A8855;
  --amplify-primary-tint: #53d69f;
}

$primary: #3bb482;

$navcolor: #0A8855;
amplify-oauth-button {
  display: none;
}

$test: #19BF00;

@import '~bootstrap/scss/bootstrap.scss';

.bg-nav {
  background-color: $navcolor
}

body {
  padding-top: 5rem;
  // padding-bottom: 5rem;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#announcements-notif::after{
  display: none;
}

.fadeIn {
  animation-name: fadeIn;
  animation-duration: 1.5s;
}

@keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}